import { render, staticRenderFns } from "./YouthHomeReports.vue?vue&type=template&id=a5744f40&scoped=true&"
import script from "./YouthHomeReports.vue?vue&type=script&lang=js&"
export * from "./YouthHomeReports.vue?vue&type=script&lang=js&"
import style0 from "./YouthHomeReports.vue?vue&type=style&index=0&id=a5744f40&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5744f40",
  null
  
)

export default component.exports